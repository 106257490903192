// import dependencies 
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { useSelector } from 'react-redux'

// import assets
import cloud from '../img/cloud.svg'
import arrowRight from '../img/arrow-right.svg'
import arrowDown from '../img/arrow-down.svg'


const ViewLogs = ({ installations }) => {

    // component state 
    const [unSuccessFullInstalls, setUnSucessFullInstalls] = useState([])
    const [successFullInstalls, setSucessFullInstalls] = useState([])
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)

    // on installations change, populate install arrays
    useEffect(() => {
        if (installations) {
            const badInstalls = installations.filter(install => !install.installationStatus?.successful)
            const goodInstalls = installations.filter(install => install.installationStatus?.successful)
            setUnSucessFullInstalls(badInstalls)
            setSucessFullInstalls(goodInstalls)
        }
    }, [installations])

    const handleUnpairBtnClick = () => {
        alert('Handle Unpair Btn')
    }

    return (
        <>
            <motion.div
                className='full-page logs-page'
                initial={{ top: '100%' }}
                animate={{ top: '80px' }}
                exit={{ top: '100%' }}
                transition={{ duration: 0.4 }}
            >

                {installationTypeSelected === 'field_install' ? (
                    <>
                        <div className='container pt-0 text-primary h-100'>

                            {/*  Redner Unsuccessful Installs only */}
                            <div className=''>
                                <p className="fs-2 fw-bold text-nowrap">{t('unsuccessful_installs_table')}</p>

                                {/* Checking if length > 1 */}
                                {unSuccessFullInstalls.length > 0 ? <>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="fw-bold text-nowrap">{t('install_table_header_1')}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="fw-bold text-center mb-0">{t('install_table_header_2')}</p>
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                    {unSuccessFullInstalls.map((install, i) => (
                                        <div className={`row py-2 ${i !== unSuccessFullInstalls.length - 1 ? 'border-bottom' : ''}`} key={i}>
                                            <div className="col-6">
                                                <p className="mb-0 mt-2 text-nowrap">{install.serialNum}</p>
                                            </div>
                                            {!install.submittedToDB ? (
                                                <div className="col-4 text-center">
                                                    <img src={cloud} className="img-fluid mt-2" alt="ubi cloud icon" />
                                                </div>
                                            ) : <div className="col-4">
                                                <div className='status-circle-red mx-auto mt-2'></div>
                                            </div>}

                                            <div className="col-2">
                                                <Link to={'/logs/node/' + install.serialNum} state={{ node: install.serialNum }}>
                                                    <img src={arrowRight} alt="ubi arrow right" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </> : <div className='text-center text-muted'>{t('no_installs')}</div>}
                            </div>

                            {/*  Render Successful Installs only */}
                            <div className='' style={{ marginTop: '55px' }}>
                                <p className="fs-2 fw-bold text-nowrap">{t('successful_installs_table')}</p>

                                {successFullInstalls.length > 0 ? <>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="fw-bold text-nowrap">{t('install_table_header_1')}</p>
                                        </div>
                                        <div className="col-4">
                                            <p className="fw-bold text-center mb-0">{t('install_table_header_2')}</p>
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                    {successFullInstalls.map((install, i) => (
                                        <div className={`row py-2 ${i !== successFullInstalls.length - 1 ? 'border-bottom' : ''}`} key={i}>
                                            <div className="col-6">
                                                <p className="mb-0 mt-2 text-nowrap">{install.serialNum}</p>
                                            </div>
                                            <div className="col-4">
                                                <div className='status-circle-green mx-auto mt-2'></div>
                                            </div>
                                            <div className="col-2">
                                                <Link to={'/logs/node/' + install.serialNum} state={{ node: install.serialNum }}>
                                                    <img src={arrowRight} alt="ubi arrow right" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </> : <div className='text-center text-muted'>{t('no_installs')}</div>}
                            </div>
                        </div>

                        {/* Divider for spacing  */}
                        <div style={{ height: '50px' }}></div>

                        {/* Page Navigator Link  */}
                        <div className={`click-to-slide-up display-at-bottom`} >
                            <Link to={'/logs/map'} className='d-flex justify-content-center 
        text-light align-items-center text-decoration-none'
                            >
                                <span className='ps-4'>{t('map_view')}</span>
                                <img className="ubi-arrow" src={arrowDown} alt="ubi arrow down" />
                            </Link>
                        </div>
                    </>
                ) : (

                    // Render the factory installations
                    <div className="container pt-0 text-primary h-100">
                
                        <div className=''>
                            {installations && installations.length > 0 ? <>
                                <div className="row">
                                    <div className="col-6">
                                        <p className="fw-bold text-nowrap">{t('store_room_table_header_1')}</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="fw-bold text-center mb-0">{t('store_room_table_header_2')}</p>
                                    </div>

                                    <div className="col-2"></div>
                                </div>
                                {installations.map((install, i) => (
                                    <div className={`row py-2 ${i !== installations.length - 1 ? 'border-bottom' : ''}`} key={i}>
                                        <div className="col-6">
                                            <p className="mb-0 mt-2 text-nowrap">{install.serialNum}</p>
                                        </div>
                                        <div className="col-4">
                                            <div className='status-circle-green mx-auto mt-2'></div>
                                        </div>
                                        <div className="col-2">
                                                <Link to={'/logs/node/' + install.serialNum} state={{ node: install.serialNum }}>
                                                    <img src={arrowRight} alt="ubi arrow right" />
                                                </Link>
                                        </div>
                                    </div>
                                ))}
                            </> : <div className='text-center text-muted'>{t('no_installs')}</div>}
                        </div>
                    </div>
                )}



            </motion.div>
        </>

    )
}

export default ViewLogs