// import dependencies 
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import { t } from 'i18next';

// import API redux actions 
import { getInstallations, unpairInstallation, checkConnection } from '../redux/actions'

// import components
import DtmCheckConnectionTable from '../components/DtmCheckConnectionTable'
import UserPrompt from '../components/UserPrompt';

const DtmNodePage = ({ selectedInstallation, setSelectedInstallation, installations, installationSubmitted }) => {
    
    // Hooks 
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    // Global States 
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)
    const checkConnectionState = useSelector(state => state.checkConnection)

    // Component States 
    const [showUnpairPrompt, setShowUnpairPrompt] = useState(false)

    // On each page refresh, find selected and node 
    useEffect(() => {
        setSelectedInstallation(null)

        // if no installations, call API 
        if (!installations) {
            dispatch(getInstallations())
            return
        }

        // node can come from url or from the url state 
        // depneding whetere user clicked from map or log page 
        const node = location.state?.node.serialNum || location.pathname.split('/')[3]

         // look for the node on installs array and set to the component state 
         if (node) {
            const foundNode = installations.find(install => install.serialNum === node)
            setSelectedInstallation(foundNode)
        }
    // eslint-disable-next-line
    }, [location, installations, installationSubmitted, dispatch])

    // Call the Recheck API to check DTM connection 
    const handleReCheckBtnClick = () => {
        // TODO : define if we need to check for relay 
        console.log(checkConnectionState)
        dispatch(checkConnection(selectedInstallation.imei, selectedInstallation.installedAusRelay > 0,selectedInstallation.installedTempSensor > 0 ))
    }

    // Call the Unpair API to erase installation
    const handleUnpair = () => {
        dispatch(unpairInstallation(selectedInstallation))
        setShowUnpairPrompt(false)
        navigate('/logs/view')
    }

    // Check DTM connection automatically on first load or when selected install changes
    useEffect(() => {
        if(selectedInstallation && installationTypeSelected === "field_install"){
            // TODO : define if we need to check for relay 
            dispatch(checkConnection(selectedInstallation.imei, selectedInstallation.installedAusRelay > 0,selectedInstallation.installedTempSensor > 0 ))
        }
    }, [selectedInstallation, dispatch])

    return (
        <>
            <motion.div
                initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 500, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className={`full-page map-node-page`}
                >
                <div className='container py-4'>
                    {selectedInstallation ? (
                        <>
                            {/* Title */}
                            <div className="d-flex justify-content-start">
                                <p className="fw-bold fs-3 m-0 text-primary">{selectedInstallation.serialNum}</p>
                                {selectedInstallation.installationStatus?.successful || installationTypeSelected === 'store_room'
                                    ? <div className='status-circle-green'></div>
                                    : <div className='status-circle-red'></div>}
                            </div>

        
                            <div className='px-2'>
                                {/* Main table to display all of DTM details  */}
                                <DtmCheckConnectionTable selectedInstallation={selectedInstallation}/>
                                <div className='text-center d-flex justify-content-around mt-3 gap-3 fixed-width-btns'>
                                    <button className='btn btn-secondary text-primary standard-btn fw-bold' onClick={() => { setShowUnpairPrompt(true) }}>{t('unpair')}</button>
                                    {installationTypeSelected === "field_install" && !selectedInstallation.installationStatus?.successful && <button onClick={handleReCheckBtnClick} className='btn btn-primary standard-btn fw-bold flex-grow-1'>{t('recheck')}</button>}
                                </div>
                            </div>
                        </>
                    ) : <div className='container py-4 text-center'>
                        <p className="fs-3">{t('node_not_found')}</p>
                        </div>}
                </div>
            </motion.div>

            {/* Show Unpair Prompt after cliking unpair btn */}
            {showUnpairPrompt && <UserPrompt data={{
                show: true,
                questionTitle: t('unpair_title'),
                questionText: t('unpair_text'),
                onAgree: handleUnpair,
                onDisagree: () => setShowUnpairPrompt(false),
                agreeText: t('unpair'),
                disagreeText: t('cancel')
            }} />}
        </>

    )
}

export default DtmNodePage