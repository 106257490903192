import React, { useState, useEffect, useContext } from "react";
import CryptoJS from 'crypto-js'
import { components } from 'react-select';
import arrowDown from '../src/img/arrow-down.svg'
import { db } from "./db";

const SECRET_IV = process.env.REACT_APP_SECRET_IV
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY


const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true)

  useEffect(() => {
    setOnlineStatus(navigator.onLine);
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

export const getCurrentLocation = (cb) => {
    let coordinates
    
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
  
    function success(pos) {
      coordinates = pos.coords;  
      cb(coordinates)
    }
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      cb(err)
    }
  
    navigator.geolocation.getCurrentPosition(success, error, options);
  
  
  }

export const getLongAndLat = () => {


  return new Promise((resolve, reject) =>{
      // function showError(error) {
      //   switch(error.code) {
      //     case error.PERMISSION_DENIED:
      //      alert("User denied the request for Geolocation.")
      //       break;
      //     case error.POSITION_UNAVAILABLE:
      //      alert("Location information is unavailable.")
      //       break;
      //     case error.TIMEOUT:
      //      alert("The request to get user location timed out.")
      //       break;
      //     case error.UNKNOWN_ERROR:
      //      alert("An unknown error occurred.")
      //       break;
      //   }
      // }
      return navigator.geolocation.getCurrentPosition(resolve, reject)
  });
}

export const encryptPass = credentials =>{
  const secret_key = CryptoJS.enc.Hex.parse(SECRET_KEY);
  const secret_iv = CryptoJS.enc.Hex.parse(SECRET_IV);
  const encryptedString = 
  CryptoJS.AES.encrypt(JSON.stringify(credentials),secret_key,
  {
    iv: secret_iv,
    padding: CryptoJS.pad.ZeroPadding,
  }).toString();

  return JSON.stringify(encryptedString.trim());
}

// dropdown indicator for react-select
export const DropdownIndicator = props => {
  return <components.DropdownIndicator {...props}><img src={arrowDown}/></components.DropdownIndicator>
}

export const clearInstallLocalStorage = () => {
  localStorage.removeItem('installState')
  localStorage.removeItem('installConnection')

  // clear index db 
  db.images.clear()
}