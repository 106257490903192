// import dependencies 
import React, { useState, useEffect } from 'react'
import { t } from 'i18next'

// import assets
import arrowDown from '../img/arrow-down.svg'

// import components
import UserPrompt from '../components/UserPrompt'
import { useSelector } from 'react-redux'

const InputKva = ({ handleKvaSubmit}) => {

    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)

    // Constants
    const TRANSFORMER_TYPES = {
        [t('aerial')]: [10, 25, 50, 75, 100],
        [t('single_phase_pad_mount')]: [25, 50, 75, 100, 167],
        [t('three_phase_pad_mount')]: [150, 300, 500, 1000, 1500]
    }

    // Component State 
    const [showOtherKva, setShowOtherKva] = useState(false)
    const [typeState, setTypeState] = useState(null)
    const [kvaSelectValue, setKvaSelectValue] = useState(null)
    const [kvaTextInput, setKvaTextInput] = useState('')
    const [readyToSubmit, setReadyToSubmit] = useState(false)

    // on user clicking on tranformer type, save clicked type to state and local storage
    const handleTypeSectionSelect = (type,e) => {
        e.preventDefault()
        setKvaSelectValue(null)
        setShowOtherKva(false)
        setKvaTextInput('')
        setTypeState(type)

        localStorage.setItem('transformer_type', type)
    }

    // on user selecting a kva from the radio btns
    const handleRegularKvaSelect = (kvaRating) => {
        setKvaSelectValue(kvaRating)
        setShowOtherKva(false)
        setKvaTextInput('')
    }


    // on user selecting "other" on the radio btns
    const handleOtherSelectClick = () => {
        setKvaSelectValue(null)
        setShowOtherKva(true)
    }

    // prompt for relaycheck prompt after hitting continue btn
    const handleContinueBtn = () => {
        handleSubmit()
    }

    // if there is a kva radio btn selected, use that , if not use the text 
    const handleSubmit = () => {
        if(typeState){
            if(kvaSelectValue){
                handleKvaSubmit(typeState, kvaSelectValue)
            } else if (kvaTextInput.length > 0){
                handleKvaSubmit(typeState, kvaTextInput)
            }
        }
    }

    // Determine when to enable/disable submit btn
    useEffect(() => {
        if(typeState && (kvaSelectValue || kvaTextInput.length > 0)){
            setReadyToSubmit(true)
        } else{
            setReadyToSubmit(false)
        }
    }, [typeState, kvaSelectValue, kvaTextInput])

    // Check of type of transformer is in local storage already on first load 
    useEffect(() => {
        const savedType = localStorage.getItem('transformer_type')
        if(savedType) setTypeState(savedType)
    },[])

    return (
        <>
        <div className='full-page container kva-page'>
            <div>
                <p className='m-0'>{t('input_kva_text')}</p>
            </div>
            <div className="my-5 ms-1">
                <div className="my-5 d-flex flex-column gap-2">
                    {/* Check that length of transformer type > 0 */}
                    {TRANSFORMER_TYPES && Object.keys(TRANSFORMER_TYPES).length > 0 &&
                        <div>
                            {/* Loop transformer types array  */}
                            {Object.keys(TRANSFORMER_TYPES).map((type, key) => (

                                <div key={key} className={`kvaExpand ${typeState === type && 'expanded'}`}>

                                    <button className="text-decoration-none d-flex 
                                    justify-content-between align-items-center w-100 border-0 bg-transparent text-primary"
                                        onClick={(e) => handleTypeSectionSelect(type,e)}>
                                        <span>{type}</span>
                                        <img className="ubi-arrow" src={arrowDown} alt="ubi arrow down" />
                                    </button>

                                    
                                    {typeState === type && <div className={`kva-menu ms-5 show`}>
                                        {TRANSFORMER_TYPES[type].map((kvaRating, key) => (
                                            // Regular select element
                                            <div className="form-check my-2 ubi-radio-input" key={key}>
                                                <input className="form-check-input" name="kvaInput"
                                                    value={kvaRating} onChange={() => handleRegularKvaSelect(kvaRating)} type="radio" />
                                                <label className={`form-check-label ${kvaSelectValue === kvaRating ? 'fw-bold' : ''}`}>
                                                    {kvaRating} kVa
                                                </label>
                                            </div>
                                        ))}

                                        {/* Other Select element  */}
                                        <div className="form-check my-2 ubi-radio-input">
                                            {<input className="form-check-input" name="kvaInput" id="kvaInputOther" 
                                                value="other" onChange={handleOtherSelectClick} type="radio" />}
                                            <label className="form-check-label" htmlFor="kvaInputOther">{t('other')}</label>
                                        </div>
                                        
                                        {/* Show other input text */}
                                        {showOtherKva && 
                                            <input type="tel" className="ubi-text-input w-50 my-2 ms-3 pb-1" 
                                                placeholder="Enter kVa" value={kvaTextInput} 
                                                onChange={e=>setKvaTextInput(e.target.value)} 
                                        />}
                                    </div>}

                                </div>
                            ))}
                        </div>}
                </div>
            </div>
            <div className='mb-4'>
                {installationTypeSelected === 'field_install' && <p>{t('confirm_unit_text')}</p>}
            </div>
            <div className='text-center mb-4 mt-5'>
                <button onClick={handleContinueBtn} 
                    className={`btn btn-primary standard-btn fw-bold ${!readyToSubmit && 'btn-disabled'}`}>
                        {t('continue')}
                    </button>
            </div>

        </div>
        </>
    )
}

export default InputKva