// import dependencies 
import React, { useState } from 'react'
import Camera from 'react-html5-camera-photo';
import { t } from 'i18next';

/**
 * 
 * @param {Function} submit 
 * @returns 
 */
const TakeInstallPic = ({submit, text, icon}) => {

    // component state 
    const [installPic, setInstallPic] = useState(null)

    // on user picture taken, compress and set the image
    const handleTakeInstallPic = file => {
        setInstallPic(file)
    }

    const handleSubmit = () => {
        if(installPic){
            const pic = installPic
            setInstallPic(null)
            submit(pic)
        }   
    }

    return (
        <div className={`full-page ${installPic ? 'installed-unit' : ''}`}>
            <div className='camera-container'>
                {!installPic ? (
                    <div className='camera'>
                        <Camera idealFacingMode="environment" onTakePhoto={(dataUri) => { handleTakeInstallPic(dataUri)}} isMaxResolution={true}/>
                        {/* Container for CSS blur  */}
                        <div className="gradient-container">
                            <div className='position-absolute d-flex justify-content-between align-items-center' style={
                                { top: icon ? "4%" : "8%", left: "4%", width : "calc(100% - 30px)", verticalAlign: "center",
                                paddingLeft : '13px', paddingRight : '13px', gap: '10px'}}>
                                <p className='text-light px-1 m-0'>{t(text)}</p>
                                {icon && <img className='align-middle' src={icon} alt="icon"/>}
                            </div>
                        </div>

                        {/* Retacle (White bars) */}
                        <div>
                            <div className="retacle top left horizontal"></div>
                            <div className="retacle top left vertical"></div>
                            <div className="retacle top right vertical"></div>
                            <div className="retacle top right horizontal"></div>
                            <div className="retacle bottom left horizontal"></div>
                            <div className="retacle bottom left vertical"></div>
                            <div className="retacle bottom right vertical"></div>
                            <div className="retacle bottom right horizontal"></div>
                        </div>
                    </div>
                ) : (
                    // After taking picture, display retake & accept btns
                    <div className='container'>
                        <div className="camera-show-picture">
                            <img src={installPic} alt="pic" className='img-fluid'/>
                            <div className=" d-flex justify-content-around mt-4">
                                <button className='btn btn-secondary text-primary fw-bold standard-btn' onClick={() => setInstallPic(null)}>{t('retake')}</button>
                                <button className='btn btn-primary text-light fw-bold standard-btn' onClick={handleSubmit}>{t('accept')}</button>
                            </div>
                            <br />

                        </div>

                    </div>
                )}

            </div>
        </div>
    )
}

export default TakeInstallPic