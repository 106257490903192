// import dependencies 
import { t } from 'i18next';
import React, { useState } from 'react'

// import assets 
import resetIcon from '../img/retake-icon.svg'
import unitIcon from '../img/unit-icon.svg'
import primariesIcon from '../img/primaries-icon.svg'
import secondariesIcon from '../img/secondaries-icon.svg'
import prvIcon from '../img/prv-icon.svg'
import pairingIcon from '../img/pairing-icon.svg'

// import utils 
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import component 
import NoteTaker from '../components/NoteTaker'
import { db } from '../db';

const PairedUnit = ({submit,notes, setNotes,  photos}) => {
    // component state 
    const [chosenPhoto, setChosenPhoto] = useState(null)

    const [showingNotePrompt, setShowingNotePrompt] = useState(false)

    const navigate = useNavigate()

    const getIcon = (photoName) => {
        switch (photoName) {
            case 'unitPhoto': return unitIcon
            case 'primariesPhoto': return primariesIcon
            case 'secondariesPhoto': return secondariesIcon
            case 'prvPhoto': return prvIcon
            case 'pairingPhoto': return pairingIcon
            default : return 'unitPhoto'
        }
    }

    const getUrl = (photoName) => {
        switch (photoName) {
            case 'unitPhoto': return 'unit-photo'
            case 'primariesPhoto': return 'primaries-photo'
            case 'secondariesPhoto': return 'secondaries-photo'
            case 'prvPhoto': return 'prv-photo'
            case 'pairingPhoto': return 'pairing-photo'
            default : return 'unit-photo'
        }
    }

    const handleResetBtnClick = () => {
        const path = '/install/' + getUrl(chosenPhoto.name)
        navigate(path, {state: { isRetakePhoto : true}})
    }

    const handleClickPhoto = (photo) => {
        setChosenPhoto(photo)
    }

    useEffect(() => {
        if(photos){
            if(photos.length > 0){
                setChosenPhoto(photos[0])
            }
        }
    }, [photos])

    return (
        <>
            <div className={`full-page full-page-slim container paired-unit-container ${showingNotePrompt ? '' :'overflow-scroll'} `}>
                {photos && photos.length > 0 && chosenPhoto && ( 
                        <div className="row">
                            <div className="col-9 pe-1 position-relative my-auto main-preview">
                                {chosenPhoto && <img className={`img-fluid photos w-100`} src={chosenPhoto.value} alt={chosenPhoto.key} />}
                                <div className='position-absolute d-flex flex-column'>
                                    <img className="mb-2" src={getIcon(chosenPhoto.key)} alt=""/>
                                    <img src={resetIcon} alt="reset-button" onClick={handleResetBtnClick}/>
                                </div>
                            </div>
                            <div className="col-3 ps-1 gallery">
                                {photos.filter(photo => (photo.key !== chosenPhoto.key) && photo.value).map(photo => {
                                    return <img key={photo.key} className={`img-fluid photos my-1`} src={photo.value} alt={photo.key} onClick={() => handleClickPhoto(photo)}/>
                                } )}
                            </div>
                        </div>
                )}
                <div className="d-flex justify-content-around mt-4">
                    <button className='btn btn-primary text-light fw-bold standard-btn' onClick={submit}>{t('continue')}</button>
                </div>
                <div className='mt-5'>
                    <NoteTaker title={true} notes={notes} setNotes={(notes) => setNotes(notes)} setShowingPrompt={setShowingNotePrompt}/>
                </div>
                
            </div>
        </>

    )
}

export default PairedUnit