import * as cn from './constants'

export const loginReducer = (state = {}, action) => {

    switch (action.type) {
        case cn.USER_LOGIN_REQUEST: return { loading : true }
        case cn.USER_LOGIN_SUCCESS: return { loading : false, user: action.payload}
        case cn.USER_LOGIN_FAIL: return { loading : false, error : action.payload }
        case cn.USER_LOGOUT: return { loading : false }
        default: return state
    }
}

export const globalLoaderReducer = (state = false, action) => {
    switch(action.type){
        case cn.GLOBAL_LOADER_SHOW: return true
        case cn.GLOBAL_LOADER_HIDE: return false
        default: return false
    }
}

export const appStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.APP_ONLINE: return { online : true}
        case cn.APP_OFFLINE: return { online : false}
        default: return state
    }
}

export const customerListReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.CUSTOMER_LIST_REQUEST: return { loading : true}
        case cn.CUSTOMER_LIST_SUCCESS: return { loading : false, customers : action.payload}
        case cn.CUSTOMER_LIST_FAIL: return { loading : false, error : action.payload}
        case cn.CUSTOMER_LIST_RESET: return { loading : false}
        default: return state
    }
}

export const mnsListReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.MNS_LIST_REQUEST: return { loading : true}
        case cn.MNS_LIST_SUCCESS: return { loading : false, mnsList : action.payload}
        case cn.MNS_LIST_DEFAULT: return {loading: false, mnsList : DEFAULT_MNS_NUMBERS}
        case cn.MNS_LIST_FAIL: return { loading : false, error : action.payload}
        case cn.MNS_LIST_RESET: return { loading : false}
        default: return state
    }
}

export const customerSelectedReducer = (state = null, action) => {
    switch (action.type) {
        case cn.CUSTOMER_SELECTED_SET:
            localStorage.setItem('customer', JSON.stringify(action.payload))
            return action.payload
        case cn.CUSTOMER_SELECTED_CLEAR: 
            localStorage.removeItem('customer')
            return null
        default: return state
    }
}

export const installationTypeReducer = (state = null, action) => {
    switch (action.type) {
        case cn.INSTALLATION_TYPE_SET:
            localStorage.setItem('install_type', JSON.stringify(action.payload))
            return action.payload
        case cn.INSTALLATION_TYPE_CLEAR: 
            localStorage.removeItem('install_type')
            return null
        default: return state
    }
}

export const justLoggedInReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.USER_CLEAR_JUST_LOGGED_IN_SET: return true
        case cn.USER_CLEAR_JUST_LOGGED_IN_CLEAR: return false
        default: return state
    }
}
export const checkConnectionReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.CHECK_CONNECTION_REQUEST: return { loading : true }
        case cn.CHECK_CONNECTION_SUCCESS: return { loading : false, data: action.payload}
        case cn.CHECK_CONNECTION_FAIL: return { loading : false, error : action.payload }
        case cn.CHECK_CONNECTION_RESET: return {}
        default: return state
    }
}


export const submitInstallationReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.SUBMIT_INSTALLATION_REQUEST: return { loading : true }
        case cn.SUBMIT_INSTALLATION_SUCCESS: return { loading : false, submitted: action.payload.submittedToDB, installation: action.payload}
        case cn.SUBMIT_INSTALLATION_FAIL: return { loading : false, error : action.payload }
        case cn.SUBMIT_INSTALLATION_RESET: return {}
        default: return state
    }
}

export const submitNotesReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.SUBMIT_NOTES_REQUEST: return { loading : true }
        case cn.SUBMIT_NOTES_PARTIAL: return { loading : false, submittedNotes : action.payload.submittedNotes}
        case cn.SUBMIT_NOTES_SUCCESS: return { loading : false, submittedNotes : action.payload.submittedNotes}
        case cn.SUBMIT_NOTES_FAIL: return { loading : false, error : action.payload }
        case cn.SUBMIT_NOTES_RESET: return {}
        default: return state
    }
}


export const installationListReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.INSTALLATION_LIST_REQUEST: return { loading : true}
        case cn.INSTALLATION_LIST_SUCCESS: return { loading : false, installations : action.payload}
        case cn.INSTALLATION_LIST_ADD: return { loading : false, installations : action.payload}
        case cn.INSTALLATION_LIST_FAIL: return { loading : false, error : action.payload}
        case cn.INSTALLATION_LIST_RESET: return { loading : false}
        default: return state
    }
}

export const nodeReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.NODE_REQUEST: return { loading : true}
        case cn.NODE_SUCCESS: return { loading : false, node : action.payload}
        case cn.NODE_FAIL: return { loading : false, error : action.payload}
        case cn.NODE_RESET: return { loading : false}
        default: return state
    }
}

export const installationUnpairReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.INSTALLATION_UNPAIR_REQUEST: return { loading : true, unpaired:false }
        case cn.INSTALLATION_UNPAIR_SUCCESS: return { loading : false, unpaired:true, installation : action.payload}
        case cn.INSTALLATION_UNPAIR_FAIL: return { loading : false, error : action.payload, unpaired:true}
        case cn.INSTALLATION_UNPAIR_RESET: return { loading : false, unpaired:true}
        default: return state
    }
}

export const changePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.CHANGE_PASSWORD_REQUEST: return { loading : true }
        case cn.CHANGE_PASSWORD_SUCCESS: return { loading : false, success : action.payload}
        case cn.CHANGE_PASSWORD_FAIL: return { loading : false, error : action.payload}
        case cn.CHANGE_PASSWORD_RESET: return { loading : false}
        default: return state
    }
}

export const resetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.RESET_PASSWORD_REQUEST: return { loading : true }
        case cn.RESET_PASSWORD_SUCCESS: return { loading : false, success : action.payload}
        case cn.RESET_PASSWORD_FAIL: return { loading : false, error : action.payload}
        case cn.RESET_PASSWORD_RESET: return { loading : false}
        default: return state
    }
}

export const installationReportReducer = (state = {}, action) => {
    switch (action.type) {
        case cn.INSTALLATION_REPORT_REQUEST: return { loading : true}
        case cn.INSTALLATION_REPORT_SUCCESS: return { loading : false, installations : action.payload}
        case cn.INSTALLATION_REPORT_ADD: return { loading : false, installations : action.payload}
        case cn.INSTALLATION_REPORT_FAIL: return { loading : false, error : action.payload}
        case cn.INSTALLATION_REPORT_RESET: return { loading : false}
        default: return state
    }
}

const DEFAULT_MNS_NUMBERS = [
    {
        "mns": "441125005",
        "transfomer_type": "single_phase",
        "kva": "25",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,13KV,25KVA,120/240V"
    },
    {
        "mns": "441150000",
        "transfomer_type": "single_phase",
        "kva": "50",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,13KV,50KVA,120/240V"
    },
    {
        "mns": "441175002",
        "transfomer_type": "single_phase",
        "kva": "75",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,13KV,75KVA,120/240V"
    },
    {
        "mns": "444125006",
        "transfomer_type": "single_phase",
        "kva": "25",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,23KV,25KVA,120/240V"
    },
    {
        "mns": "444150001",
        "transfomer_type": "single_phase",
        "kva": "50",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,23KV,50KVA,120/240V"
    },
    {
        "mns": "444175003",
        "transfomer_type": "single_phase",
        "kva": "75",
        "phase": "1",
        "supplier": "",
        "desc": "TX:AR,1PH,23KV,75KVA,120/240V"
    }, 
    {
        "mns" : "447125007",
        "transfomer_type" : "single_phase",
        "kva" : "25",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,13KV,25KVA,120/240V,SS",
    },
    {
        "mns" : "447150001",
        "transfomer_type" : "single_phase",
        "kva" : "50",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,13KV,50KVA,120/240V,SS",
    },
    {
        "mns" : "447175004",
        "transfomer_type" : "single_phase",
        "kva" : "75",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,13KV,75KVA,120/240V,SS",
    },
    {
        "mns" : "447225001",
        "transfomer_type" : "single_phase",
        "kva" : "25",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,23KV,25KVA,120/240V,SS",
    },
    {
        "mns" : "447250006",
        "transfomer_type" : "single_phase",
        "kva" : "50",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,23KV,50KVA,120/240V,SS",
    },
    {
        "mns" : "447275009",
        "transfomer_type" : "single_phase",
        "kva" : "75",
        "phase" : "1",
        "supplier" : "",
        "desc" : "TX:AR,1PH,23KV,75KVA,120/240V,SS",
    }
]