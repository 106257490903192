// import dependencies 
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./styles/style.scss";
import './i18n';

// import utils 

import registerServiceWorker from './serviceWorkerRegistration.js'
import store from './redux/store.js'

// import components
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);

registerServiceWorker()