// import dependencies 
import React from 'react'
import { useNavigate } from "react-router-dom";
import { t } from 'i18next';

// import assets 
import keyboardImg from '../img/keyboard.svg'

// import install components 
import { QrReader } from 'react-qr-reader';


/**
 * 
 * @param {Function} handleQrResult 
 * @returns 
 */
const QrScanner = ({handleQrResult}) => {
    
    // Hooks 
    const navigate = useNavigate()

    // on user tapping keyboard, go to manual input page
    const handleKeyBoardBtnClick = () => navigate('/install/unable-to-scan')
    
    return (
        <div id="scan-unit" className='full-page'>
            <div className="qr-container">

                <QrReader
                    showMarker={true}
                    constraints={{ facingMode: { ideal: 'environment' }, rememberLastUsedCamera : true }}
                    onResult={handleQrResult} />

                {/* Container for CSS blur  */}
                <div className='legacy-gradient-container'>
                    <div className='position-absolute' style={{ top: "35px", left: "10%" }}>
                        <p className='text-light' style={{paddingRight: '25px'}}>{t('hold_device_near')}</p>
                    </div>
                    {/* Keyboard btn  */}
                    <div className='d-flex justify-content-end mt-3 position-absolute keyboard-btn'>
                        <img src={keyboardImg} className="img-fluid" alt="" onClick={handleKeyBoardBtnClick} />
                    </div>
                </div>

                {/* Retacle (White bars) */}
                <div className=''>
                    <div className="legacy-retacle top left horizontal"></div>
                    <div className="legacy-retacle top left vertical"></div>
                    <div className="legacy-retacle top right vertical"></div>
                    <div className="legacy-retacle top right horizontal"></div>
                    <div className="legacy-retacle bottom left horizontal"></div>
                    <div className="legacy-retacle bottom left vertical"></div>
                    <div className="legacy-retacle bottom right vertical"></div>
                    <div className="legacy-retacle bottom right horizontal"></div>
                </div>
            </div>
        </div>
    )
}

export default QrScanner