import React, { useState } from 'react'
import { t } from 'i18next'
import useDigitInput from 'react-digit-input';
import { useEffect } from 'react';


const TlnNumInput = ({ submit }) => {

  // hooks 


  // component states 
  const [ readyToSubmit, setReadyToSubmit] = useState(false)
  const [inputActive, setInputActive] = useState(false)
  const [value, onChange] = useState('');


  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 11,
    value,
    onChange,
  });

  // if hitting backspace on first input, set to not active 


  // on value change 
  useEffect(() => {

    // if value is not empty, set to active 
    if(value.trim() !== ''){
        setInputActive(true)
    } 
    // else if input is active, turn back to not active
    else if(inputActive){
        setInputActive(false)
    }
    
    // check if all inputs are filled out 
    if(value.indexOf(' ') < 0 && value !== ''){
       setReadyToSubmit(true) 
    } else{
        setReadyToSubmit(false) 
    }
    
  }, [value, inputActive])

  return (
    <div className='full-page container tln-input-page'>
        <div>
            <p>{t('tln_text')}</p>
        </div>

        <div className={`tln-input ${inputActive ? 'active' : ''}`}>
            <div className="input-group d-flex justify-content-between">
                <input inputMode="decimal" autoFocus {...digits[0]} placeholder="0" />
                <span className="hyphen">-</span>
                <input inputMode="decimal" {...digits[1]} placeholder="0" />
                <input inputMode="decimal" {...digits[2]} placeholder="0" />
                <input inputMode="decimal" {...digits[3]} placeholder="0" />
                <input inputMode="decimal" {...digits[4]} placeholder="0" />
                <span className="hyphen">-</span>
                <input inputMode="decimal" {...digits[5]} placeholder="0" />
                <input inputMode="decimal" {...digits[6]} placeholder="0" />
                <input inputMode="decimal" {...digits[7]} placeholder="0" />
                <input inputMode="decimal" {...digits[8]} placeholder="0" />
                <span className="hyphen">-</span>
                <input inputMode="decimal" {...digits[9]} placeholder="0" />
                <span className="hyphen">-</span>
                <input inputMode="decimal" {...digits[10]} placeholder="0" />
            </div>

        </div>
        <div className='text-center mb-4'>
            <button
              onClick={() => submit(value)}
              className={`btn btn-primary standard-btn fw-bold ${!readyToSubmit && 'btn-disabled'}`}>{t('complete')}</button>
        </div>
        <div className="my-4 text-center" >
            <button style={{color : '#3FB3FB', backgroundColor: 'transparent', border: 'none'}} className='text-decoration-none' onClick={() => submit(null)} >{t('tln_unavailable')}</button>
          </div>
    </div>
  )
}

export default TlnNumInput