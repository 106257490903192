// import dependencies 
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as cn from '../redux/constants'


// import utils 
import { useOnlineStatus } from '../utils'

// import assets 
import rogoErrorImg from '../img/rogo_error_svg.svg'
import resetDTM from '../img/resetDTM.svg'

// import components
import DtmCheckConnectionTable from '../components/DtmCheckConnectionTable'
import UserPrompt from '../components/UserPrompt'

// import API redux actions
import { checkConnection } from '../redux/actions'
import { t } from 'i18next'

import checkCircle from '../img/check-circle.svg'
import checkCircleSelected from '../img/check-circle-selected.svg'
import xCircle from '../img/x-circle.svg'
import xCircleSelected from '../img/x-circle-selected.svg'

/**
 * 
 * @param {string} imei -  
 * @param {Function} handleConnectionContinue -  
 * @returns 
 */
const CheckDtmConnectionPage = ({imei, handleConnectionContinue, transformerType}) => {

    // hooks 
    const dispatch = useDispatch()
    const isOnline = useOnlineStatus();

    // component state 
    const [showRogoError, setShowRogoError] = useState(false)
    const [showResetDtmError, setShowResetDtmError] = useState(false)
    const [retryCounter, setRetryCounter] = useState(0)

    const [showAusTempSensorPrompt, setShowAusTempSensorPrompt] = useState(false)
    const [showTempSensorPrompt, setShowTempSensorPrompt] = useState(false)

    // User Prommpt states 
    const [promptChecks, setPromptChecks] = useState({
        ausState : null,
        tempState : null
    })

    const customerSelected = useSelector(state => state.customerSelected)

    // global states 
    const appOnline = useSelector(state => state.appStatus)
    const checkConnectionData = useSelector(state => state.checkConnection?.data)            

    // Call check connection API , if imei not defined, get it from local storage (refresh might casuse for imei to be null)
    const checkDtmConnection = (customPropChecks) => {
        const localStorageInstall = JSON.parse(localStorage.getItem('installState'))
        const dtmImei = imei ? imei : localStorageInstall.imei

        if(customPropChecks){
            dispatch(checkConnection(dtmImei, customPropChecks.ausState, customPropChecks.tempState))
        } else{
            dispatch(checkConnection(dtmImei, promptChecks.ausState, promptChecks.tempState))
        }
    }

    // on user hitting recheck, increase retry counter and dispatch check dtm api again
    const handleReCheckBtnClick = () => {
        setRetryCounter(prev => prev + 1)
        checkDtmConnection()
    }

    // Handle 2 possible error prompts, connectin error and rogo error, making sure we are only diplaying one
    const handleErrorsPrompt = () => {
      // Connection Error 
      if(checkConnectionData?.connections?.connection === false){
        setShowResetDtmError(true)
        return 
      }

      // Rogo Error, show if rogo placement prop is false & not shoing connection error
      if(checkConnectionData?.connections?.rogowski_placement === false && !showResetDtmError ){
          setShowRogoError(true)
          return
      }
    }

    // on user skip btn, render the continue btn for the user to click and go to the next page
    const handleSkipBtn = () => {
        setRetryCounter(2)
        setShowRogoError(false)
        setShowResetDtmError(false)
    }

    const handlePromptChecksResponse = () => {
        checkDtmConnection()
        setShowAusTempSensorPrompt(false)
    }

    const handleTempPromptResponse = (checkTemp) => {
        setPromptChecks({...promptChecks, tempState: checkTemp, ausState: null})
        setShowTempSensorPrompt(false)
        checkDtmConnection({tempState: checkTemp, ausState: null})
    }
    

    // On first load, show the user prompts if customer is FPL
    // else just dispatch to check connection with aus and temp sensor being null
    // if transformer type is pad mount show aus & temp prompt, if type is aerial just show temp 
    useEffect(() => {
        if(customerSelected.isFPL){
            if(transformerType === 'aerial'){
                setShowTempSensorPrompt(true)
            } else{
                setShowAusTempSensorPrompt(true)
            }
        } else{
            checkDtmConnection()
        }
    // eslint-disable-next-line
    }, [])

    // Check for errors everytime checkConnectionData changes   
    useEffect(() => {
        // clear all errors before 
        setShowRogoError(false)
        setShowResetDtmError(false)
        handleErrorsPrompt()
        if(checkConnectionData){
            localStorage.setItem('installConnection', checkConnectionData)
        }
    // eslint-disable-next-line
    }, [checkConnectionData])


    // On first load, check if check connection has been saved from before 
    // if first time loading set all prompts to 
    useEffect(() => {
        const savedConnection = localStorage.getItem('installConnection')

        if(savedConnection){
            dispatch({
                type: cn.CHECK_CONNECTION_SUCCESS,
                payload : savedConnection
              })
        }
    }, [dispatch])

    return (
        <>
            {/* Prompt user for AUS or Temp Check  */}
            {showAusTempSensorPrompt && (
            // if Aerial Install Type show Temp Sensor pop up 
            <UserPrompt data={
                {
                    questionTitle: t('confirm'), 
                    questionText: <div>
                        <div className="row">
                            <p>{t('pad_modal_text')}</p>
                        </div>
                        {/* Row for checking AUS  */}
                       <div className="row border-bottom mb-1 py-3">
                            <div className="col-6 fw-bold">{t('aus')}</div>
                            <div className="col-6 px-0">
                                <div className='d-inline'>
                                    <img className="img-fluid" alt="" src={promptChecks.ausState ? checkCircleSelected : checkCircle} 
                                    onClick={()=>{setPromptChecks({...promptChecks, ausState: true})}} />
                                </div>
                                <div className='d-inline ps-4'>
                                    <img className="img-fluid" alt="" src={promptChecks.ausState === false ? xCircleSelected : xCircle} 
                                    onClick={()=>{setPromptChecks({...promptChecks, ausState: false})}} />
                                </div>
                            </div>
                        </div> 

                        {/* Row for checking Temp Sensor */}
                        <div className="row py-3 mb-1">
                            <div className="col-6 fw-bold">{t('temp_sensor')}</div>
                            <div className="col-6 px-0">
                                <div className='d-inline'>
                                    <img className="img-fluid" alt="" src={promptChecks.tempState ? checkCircleSelected : checkCircle} 
                                        onClick={()=>{setPromptChecks({...promptChecks, tempState: true})}} />
                                </div>
                                <div className='d-inline ps-4'>
                                    <img className="img-fluid" alt="" src={promptChecks.tempState === false ? xCircleSelected : xCircle} 
                                        onClick={()=>{setPromptChecks({...promptChecks, tempState: false})}}  />
                                </div>
                            </div>
                        </div> 
                    </div>, 
                    agreeText: t("Continue"),
                    disableAgreeBtn : (promptChecks.ausState === null || promptChecks.tempState === null),
                    onAgree: () => handlePromptChecksResponse(),
                }} 
            />)} 

            {/* Prompt user for Temp Check */}
            {showTempSensorPrompt && (
                <UserPrompt data={{
                    questionTitle: t('temp_sensor'),
                    questionText : t('temp_sensor_modal_text'),
                    agreeText: t('yes'),
                    disagreeText: t('no'),
                    onAgree: () => handleTempPromptResponse(true),
                    onDisagree: () => handleTempPromptResponse(false)
                }} />
            ) }

            {/* Rogo error */}
            {showRogoError && (
                <UserPrompt data={
                    {
                        questionTitle: 'Sec. Rogowski Error',
                        questionText:
                            <div className=''>
                                <span>
                                    {t('verify')}: <br />
                                    <span className='fw-bold'>A.</span> {t('verify_rogo_a')}
                                    <br />
                                    <span className='fw-bold'>B.</span> {t('verify_rogo_b')}
                                </span>
                                <div className='text-center'>
                                    <img className="img-fluid text-center prompt-img" src={rogoErrorImg} alt="Rogoswki Coil Error" />
                                </div>
                            </div>, 
                        agreeText: t('try_again'),
                        disagreeText: t('skip'),
                        onAgree: () => setShowRogoError(false),
                        onDisagree: retryCounter > 0 ? () => handleSkipBtn() : null
                    }
            } />)}

            {/* Reset DTM Prompt */}
            {showResetDtmError && (
                <UserPrompt data={
                    {
                        questionTitle: t('connection_failure'),
                        questionText:
                            <div className=''>
                                <span >{t('dtm_failed_connect')}</span>
                                <div className='text-center my-4'>
                                    <img className="img-fluid text-center prompt-img" src={resetDTM} alt="Connection Error"/>
                                </div>
                            </div>, 
                        agreeText: t('try_again'),
                        disagreeText: t('skip'),
                        onAgree: () => setShowResetDtmError(false),
                        onDisagree: retryCounter > 0 ? () => handleSkipBtn() : null
                    }
            } />)}

            {/* Actual Table */}
            <div className='full-page container check-dtm-page'>
                <div className='mb-2'>
                    <p className='mb-0'>{t('connection_verification_text_1')}</p>
                </div>

                <DtmCheckConnectionTable/>

                {/* Show verify connection error */}
                {checkConnectionData && !checkConnectionData.successful && 
                    <p className='small my-2'>{t('connection_error_detected')}</p>}   

                {/* Continue and Re-CheckBtns */}
                <div className='text-center mt-5'>
                    {/* if app is not online show continue */}
                {!appOnline || !isOnline 
                ? <button onClick={() => handleConnectionContinue(promptChecks)} className="btn btn-primary fw-bold standard-btn">{t('continue')}</button>

                // if connection not successful and retry < 3, show recheck
                : checkConnectionData && !checkConnectionData.successful && retryCounter < 2 
                ? <button className='btn btn-primary standard-btn fw-bold' onClick={handleReCheckBtnClick}>{t('recheck')}</button>

                // else just show continue
                : <button onClick={() => handleConnectionContinue(promptChecks)} className="btn btn-primary standard-btn fw-bold">{t('continue')}</button>
                }
                </div>  
            </div>
        </>
    )
}

export default CheckDtmConnectionPage

