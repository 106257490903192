// import dependencies 
import React, { useEffect, useState  } from 'react'
import * as cn from '../redux/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

// import components
import BottomAlert from '../components/BottomAlert'


// import API redux actions
import { resetPassword } from '../redux/actions'

const ForgotPassword = () => {
    // Hooks
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Component State 
    const [email, setEmail] = useState('')

    // Global State 
    const resetPasswordError = useSelector(state => state.resetPassword?.error)
    const resetPasswordSuccess = useSelector(state => state.resetPassword?.success)

    const handleResetBtnClick = () => {
        dispatch(resetPassword(email))
    }

    useEffect(() => {
        if(resetPasswordSuccess){
            setTimeout(() => {
                dispatch({type : cn.RESET_PASSWORD_RESET })
                navigate('/login')
            }, 2000);
        }
    // eslint-disable-next-line
    }, [resetPasswordSuccess ])


  return (
    <>
        <div className='full-page container'>
            <div>
                <p>{t('forgot_password_page_text')}</p>
            </div>
            <div className='input-group my-4'>
                    <div className="mb-3 w-100">
                        <input type="email" className="ubi-text-input"
                        id="email3" value={email} onChange={e => setEmail(e.target.value) }
                         autoComplete="off" placeholder={t('enter_email')}/>
                    </div>
            </div>
            {/* Disable btn if email field is empty */}
            <div className='text-center'>
                <button onClick={handleResetBtnClick} 
                    className={`btn btn-primary px-4 ${email.length < 1 ? 'btn-disabled' : ''}`}
                >{t('forgot_password_btn')}
            </button>
            </div>
        </div>
        {resetPasswordSuccess && <BottomAlert text={t('reset_email_sent')} icon={false}/>}
        {resetPasswordError && <BottomAlert text={t('there_was_error')} icon={false}/>}
    </>

  )
}

export default ForgotPassword