// import dependencies
import { t } from 'i18next';
import { useState } from 'react';

// import assets
import loop from '../img/loop.png';

export const ErrorModal = ({ title = 'Title', body = "Test", btnText = "ok", onClick }) => {
  return <Modals modal={<>
    <p className='page-card-title'>{title}</p>
    <div>{body}</div>
    <br />
    <div className={`row`}>
      <div className="col ml-md-auto">
        <button className={`ubi-button px-4 w-100 text-nowrap`} onClick={onClick}>
          {t(btnText)}
        </button>
      </div>
    </div>
  </>} />;
};

export const GeneralModal = ({ title = 'Title', body = "Test", btnText = "ok", onClick }) => {
  return <Modals modal={<>
    <p className='page-card-title'>{title}</p>
    <div>{body}</div>
    <br />
    <div className={`row`}>
      <div className="col ml-md-auto">
        <button className={`ubi-button px-4 w-100 text-nowrap`} onClick={onClick}>
          {t(btnText)}
        </button>
      </div>
    </div>
  </>} />;
};

export const SuccessModal = ({ title = 'Title', body = "Test", btnText = "ok", onClick }) => {
  return <Modals modal={<>
    <p className='page-card-title'>{title}</p>
    <div>{body}</div>
    <br />
    <div className={`row`}>
      <div className="col ml-md-auto">
        <button className={`ubi-button px-4 w-100 text-nowrap`} onClick={onClick}>
          {t(btnText)}
        </button>
      </div>
    </div>
  </>} />;
};

export const PromptModal = ({ choices = [], title = 'Title', body = "Test" }) => {
  return <Modals modal={<>
    <p className='page-card-title'>{title}</p>
    <div>{body}</div>
    <br />
    <div className={`row text-center px-4`}>
      {choices.map((choice, index, arr) => (
        <div key={choice.text} className={index === arr.length - 1 || arr.length < 3 ? 'col ml-md-auto' : 'col ml-md-auto mb-4'}>
          <button className={`${choice.btnClass} px-4 w-100 text-nowrap`} onClick={choice.onClick}>
            {t(choice.text)}
          </button>
        </div>
      ))}
    </div>
  </>} />;
};

export const TextNoteModal = ({ title, placeholder = 'Begin Typing...', maxInput = 500, onCancel, onDone, defaultValue }) => {
  const [text, setText] = useState(defaultValue ? defaultValue : '');

  const handleInput = (text) => {
    if (text.length > maxInput) return;
    setText(text);
  };
  return <Modals modal={<>
    <p className='page-card-title'>{title}</p>
    <textarea className="ubi-text-area" id="" cols={30} rows={6} placeholder={placeholder} value={text} onChange={e => handleInput(e.target.value)}>
    </textarea>
    <span className='ubi-text-area-counter'>{text.length}/{maxInput}</span>
    <div className="d-flex justify-content-between mx-3 mt-4">
        <button className={`ubi-button secondary-btn text-nowrap`} onClick={onCancel}>{t('cancel')}</button>
        <button className={`ubi-button text-nowrap`} onClick={() => onDone(text)}>{t('done')}</button>
    </div>
  </>} />;
};

export const Loader = ({ disabledOverlay = true, text = 'Loading' }) => {
  return (
    <div className='ubi-prompt'>
      {disabledOverlay && <div className="spinner-overlay"></div>}
      <div className='ubi-spinner'>
        <span>{text}</span>
        <img src={loop} alt="" />
      </div>
    </div>
  );
};

const Modals = ({ modal = <></> }) => {
  return (
    <div className='ubi-prompt'>
      <div className="overlay"></div>
      <div className='prompt'>
        {modal}
      </div>
    </div>
  );
};

export default Modals;
