import {Workbox} from 'workbox-window'


export default function registerServiceWorker(){
  console.log(process.env.NODE_ENV)
  if(process.env.NODE_ENV !== 'production') {
    return
  }

  if('serviceWorker' in navigator){
    const wb = new Workbox('service-worker.js')

    wb.addEventListener('installed', e => {
      if(e.isUpdate){
        window.location.reload()
      }
    })

    wb.register()
  }
}


