import React from 'react'
import cloud from '../img/cloud.svg'

const BottomAlert = ({text, icon = false}) => {
  return (
    <div className="bg-secondary text-primary position-absolute bottom-0 w-100 ubi-top-rounded fw-bold" style={{zIndex: 3}}>
                <div className='d-flex justify-content-center 
                align-items-center text-decoration-none' style={{padding: '13px 0px'}}>
                    <span style={{fontSize: '15px'}}>{text}</span>
                    {icon && <img className="mx-2 ubi-cloud" src={cloud} alt="ubi cloud icon"/>}
                </div>
    </div>
  )
}

export default BottomAlert