// import dependencies 
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import Camera from 'react-html5-camera-photo';
import Compress from "react-image-file-resizer";
import { t } from 'i18next';
import keyboardImg from '../img/keyboard.svg'

// import utils
import { useEffect } from 'react';

const PlateScanner = ({submit}) => {

    // hooks
    const navigate = useNavigate()

    // global state 
    const installationTypeSelected = useSelector(state => state.installationTypeSelected)

    // component state 
    const [platePic, setPlatePic] = useState(null)

    // on user picture taken, compress and set the image
    const handleTakeInstallPic = file => {
        setPlatePic(file)
    }

    const handleSkipBtn = () => {
        submit('skip')
    }

    const handleKeyBoardBtnClick = () => navigate('/install/plate-manual-input')

    const handleSubmit = () => {
        if(platePic) submit(platePic)
    }

    // try to get platePic from local storage on first load
    useEffect(() => {
        const storedInstallPic = localStorage.getItem('platePic')

        if(storedInstallPic && storedInstallPic !== 'skip' ) setPlatePic(storedInstallPic)
    }, [])

    return (
        <div className={`full-page ${platePic ? 'installed-unit' : ''}`}>
            <div className='camera-container'>
                {!platePic ? (
                    <div className='camera'>
                        <Camera idealFacingMode="environment" onTakePhoto={(dataUri) => { handleTakeInstallPic(dataUri); }} />

                        {/* Container for CSS blur  */}
                        <div className="gradient-container">
                            <div className='position-absolute' style={{ top: "15px", left: "9%", width: '80%' }}>
                                <p className='text-light px-1 my-3'>{t('transformer_plate_text')}</p>
                            </div>

                            {installationTypeSelected.value === 'field_install' && (
                                <div className='d-flex justify-content-end mt-3 position-absolute keyboard-btn'>
                                    <button className='btn btn-secondary btn-small fw-bold text-primary px-3' onClick={handleSkipBtn}>{t('skip')}</button>
                                </div>
                            )}

                        </div>

                        {/* Retacle (White bars) */}
                        <div>
                            <div className="retacle top left horizontal"></div>
                            <div className="retacle top left vertical"></div>
                            <div className="retacle top right vertical"></div>
                            <div className="retacle top right horizontal"></div>
                            <div className="retacle bottom left horizontal"></div>
                            <div className="retacle bottom left vertical"></div>
                            <div className="retacle bottom right vertical"></div>
                            <div className="retacle bottom right horizontal"></div>
                        </div>
                    </div>
                ) : (
                    // After taking picture, display retake & accept btns
                    <div className='container'>
                        <div className="camera-show-picture">
                            <img src={platePic} alt="" className='img-fluid' />
                            <div className=" d-flex justify-content-around  mt-4">
                                <button className='btn btn-secondary text-primary fw-bold standard-btn' onClick={() => setPlatePic(null)}>{t('retake')}</button>
                                <button className='btn btn-primary text-light fw-bold standard-btn' onClick={handleSubmit}>{t('accept')}</button>
                            </div>
                            <br />

                        </div>

                    </div>
                )}

            </div>
        </div>
    )
}

export default PlateScanner