import { t } from 'i18next'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getMnsList } from '../redux/actions'

const MnSNumber = ({handleMnsSubmit}) => {

  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [selectedMns, setSelectedMns] = useState(null)

  const mnsList = useSelector(state => state.mnsList.mnsList)
  const mnsListLoading = useSelector(state => state.mnsList.loading)

  useEffect(() => {
    if(selectedMns){
        setReadyToSubmit(true)
    } else{
        setReadyToSubmit(false)
    }
  },[selectedMns])

  return (
    <div className='full-page container'>
        <p>{t('select_mns_text')}</p>

        <div className='ms-5'>
            {mnsList && mnsList.length > 0 && mnsList.map(mns => (
                <div className="form-check my-3 ubi-radio-input" key={mns.mns}>
                    <input className="form-check-input" name="mnsInput"
                        value={mns.mns} onChange={() => setSelectedMns(mns.mns)} type="radio" />
                    <label className={`form-check-label ${selectedMns === mns.mns ? 'fw-bold' : ''}`}>
                        {addDashes(mns.mns)}
                    </label>
                </div>
            ))}
        </div>


        <div className='text-center mb-4 mt-5'>
            <button onClick={() => handleMnsSubmit(selectedMns)} 
                className={`btn btn-primary standard-btn fw-bold ${!readyToSubmit && 'btn-disabled'}`}>
                    {t('continue')}
            </button>
        </div>

        <div className="mb-4 text-center" >
            <Link style={{color : '#3FB3FB'}} className='text-decoration-none' to={"/install/input-mns"}>{t('enter_mns_manually')}</Link>
        </div>

    </div>
  )
}

export default MnSNumber

const addDashes = (mns) => mns.slice(0,3) + "-" + mns.slice(3,8) + "-" + mns.slice(8,9)

