import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// import assets 
import loop from '../img/loop.png'

// import api 
import { getFile, getInstallationsReport } from '../redux/actions'
import UserPrompt from '../components/UserPrompt'

const AdminPage = () => {

    // local state 
    const [selectedDtm, setSelectedDtm] = useState(null)

    // filters
    const [from,setFrom] = useState(moment().subtract(3, 'days').format('YYYY-MM-DD'))
    const [to,setTo] = useState(moment().format('YYYY-MM-DD'))
    const [serialNum, setSerialNum] = useState(null)
    const [kva, setKva] = useState(null)
    const [mns, setMns] = useState(null)
    const [email, setEmail] = useState(null)

    // Global State 
    const {installations, loading} = useSelector(state => state.installationReport)
    const customerSelected = useSelector(state => state.customerSelected)

    // Hooks 
    const dispatch = useDispatch()

    const getInstalls = () => {
        const options = {
            from,to
        }

        if(serialNum)options.serialNum = serialNum
        if(kva) options.kva = kva
        if(mns) options.mns = mns
        if(email) options.email = email

        dispatch(getInstallationsReport(options))
    }
    // jsonData, filename
  const downloadJSONAsCSV = () => {

    // convert utc time to locale time 
    const jsonData = installations.map(install => {
      if(install.datePaired){
        install.datePaired = moment.utc(install.datePaired).local().format('M/D/YY h:mm A')
      }
      if(install.dateInstalled){
        install.dateInstalled = moment.utc(install.dateInstalled).local().format('M/D/YY h:mm A')
      }
      return install
    })
    

    // const jsonData = installations
    
    const filename = `${customerSelected.customer_id}_installs_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}.csv`

    // Check if jsonData is not an array of objects
    if (!Array.isArray(jsonData) || typeof jsonData[0] !== 'object') {
        throw new Error('Invalid JSON data for CSV conversion');
    }

    // Get object keys
    const keys = Object.keys(jsonData[0]);

    // Convert JSON data to CSV
    const csv = [
        keys.join(','), // keys
        ...jsonData.map(obj => keys.map(key => obj[key]).join(',')) // data
    ].join('\n');

    // Create download link and click it
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  // get installs as soon as page loads
  useEffect(() =>{
    getInstalls()
  }, [])

  return (
    <>
    <div className='full-page container' style={{maxWidth: '100%',overflow: 'scroll'}}>
        {/* filter adding section */}

        <div className='d-flex justify-content-between gap-5'>
          <div>
              <span className='fw-bold'>Customer Selected <br /> </span>
              <span style={{verticalAlign: "sub"}}>{customerSelected && customerSelected.name + ' (' + customerSelected.customer_id  +')' }</span>
          </div>

          <div className='flex-grow-1'></div>
          <div>
              <button type="button" className="btn btn-outline-primary h-100" onClick={downloadJSONAsCSV}>Export CSV <i className="bi bi-save"></i></button>
          </div>
          <div>
              <button type="button" className="btn btn-primary h-100" onClick={getInstalls}>Load Installs</button>
          </div>
        </div>

        <div className="row my-4">
          <div className='col'>
              <span className='fw-bold'>Date From <br /> </span>
              <input className='date-pick form-control' type="date" name="from" value={from} onChange={e => setFrom(e.target.value)}/>
          </div>
          <div className='col'>
              <span className='fw-bold'>Date To <br /> </span>
              <input className='date-pick form-control' type="date" name="to" value={to} onChange={e => setTo(e.target.value)}/>
          </div>
          <div className='col'>
              <span className='fw-bold'>SerialNum <br /> </span>
              <input type="text" className="form-control" value={serialNum ? serialNum : ''} onChange={e => setSerialNum(e.target.value)} placeholder="2308UTMD00468"></input>
          </div>
          <div className='col-1'>
              <span className='fw-bold'>kVA <br /> </span>
              <input type="number" className="form-control" value={kva ? kva : ''} onChange={e => setKva(e.target.value)} placeholder="50"></input>
          </div>
          <div className='col'>
              <span className='fw-bold'>MNS <br /> </span>
              <input type="number" className="form-control" value={mns ? mns : ''} onChange={e => setMns(e.target.value)} placeholder="441125005"></input>
          </div>
          <div className='col'>
              <span className='fw-bold'>Paired User Email <br /> </span>
              <input type="text" className="form-control" value={email ? email : ''} onChange={e => setEmail(e.target.value)} placeholder="user@example.com"></input>
          </div>
        </div>
        
        <div className="w-100 text-center">
          <span className='fw-bold'>{installations?.length > 0 ? `${installations.length} Results` : 'No Results' } </span>
        </div>

        {loading && <div className='element-spinner position-fixed top-50 start-50'>
              <span>Loading</span>
              <img src={loop} alt="" />
          </div>}
        
          <hr/>
        {installations && installations.length > 0 && (
            <>
            <table className="table ubi-table">
            <thead>
                <tr>
                <th scope="col">ID</th>
                <th scope="col">SerialNum</th>
                <th scope="col">IMEI</th>
                <th scope="col">kVA</th>
                <th scope="col">State</th>
                <th scope="col">MNS</th>
                <th scope="col">Paired Time</th>
                <th scope="col">Paired By</th>
                <th scope="col">Paired By Email</th>
                <th scope="col">Installed Time</th>
                <th scope="col">Installed By</th>
                <th scope="col">Installed By Email</th>
                </tr>
            </thead>
            <tbody>
                {installations.map(install => (
                    <tr key={install.id} onClick={() => setSelectedDtm(install)}>
                        <th scope="row">{install.id}</th>
                        <td>{install.serialNum}</td>
                        <td>{install.imei}</td>
                        <td>{install.kva}</td>
                        <td>{install.state}</td>
                        <td>{install.transformerInventoryNum}</td>
                        <td>{install.datePaired ? moment.utc(install.datePaired).local().format('M/D/YY h:mm A') : "-"}</td>
                        <td>{install.user}</td>
                        <td>{install.email}</td>
                        <td></td>
                        <td></td>
                        <td></td>  
                    </tr>
                ))}
            </tbody>
            </table>
            </>
        )}
    </div>

    {selectedDtm && (
        <UserPrompt data={
            {
                questionTitle: selectedDtm ? 'DTM# ' + selectedDtm.serialNum : 'DTM',
                questionText:
                <div className="row">
                  <div className="col-3">
                      <div className='d-flex flex-wrap flex-column justify-content-between gap-3'>
                          {selectedDtm && Object.keys(selectedDtm).filter(key => ['id','serialNum', 'imei', 'kva', 'state','transformerInventoryNum',
                            'datePaired', 'user', 'email', 'dateInstalled', 'userInstalled', 'userInstalledEmail'].includes(key))
                          .map( key => (
                              <div key={key}>
                                  <span className='fw-bold text-primary'>{t[key] ? t[key] : key} <br /> </span>
                                  <span className='text-dark'>{selectedDtm[key]}</span>
                              </div>
                          ))}
                      </div>
                  </div>
                  <div className="col-9">
                      {selectedDtm && <Carousel images={[selectedDtm.nameplatePhoto,...selectedDtm.ubigridPhoto, ...selectedDtm.installationPhoto]} /> }
                  </div>
                </div>, 
                agreeText: 'Close',
                onAgree: () => setSelectedDtm(null),
                fullWidth: true
            }
    } />)}
    </>
  )
}

export default AdminPage

export const ImageComponent = ({ filename , onClick, className}) => {
    const [src, setSrc] = useState(null);

    const downloadImage = () =>{
      const a = document.createElement("a");
      a.href = src;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  
    useEffect(() => {
        let isCancelled = false;
  
        if(filename){
          let random = Math.floor(Math.random() * (500 - 50 + 1)) + 50;
  
          setTimeout(()=>{
  
            getFile(filename)
            .then(blobUrl => {
                if (!isCancelled) {
                    let url = URL.createObjectURL(blobUrl);
                    setSrc(url);
                }
            })
            .catch(error => console.error(error));
          },random)
        }
  
        return () => {
            // This will cancel the setting of the image source if the component is unmounted
            // to avoid memory leaks.
            isCancelled = true;
        };
    }, [filename]);
  
    if (!src) {
        // Placeholder while img is loading 
        return <div className={'img-fluid rounded img-preview ' + className}>
          Loading...
        </div>;
    }
    return <>
      <img src={src} alt="Note Photo" className='img-fluid rounded' style={{maxHeight: '600px'}} onClick={onClick}/>
      <button className='btn btn-secondary' style={{position: 'absolute', bottom: '75px', right: '10px', zIndex: 10}} onClick={() => downloadImage()}><i className="bi bi-download"></i></button>
    </>;
  };
  
  

const Carousel = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    const goToSlide = (index) => {
      setActiveIndex(index);
    };
  
    const goToPrevSlide = (e) => {
      e.preventDefault();
  
      let index = activeIndex;
      let { length } = images;
      let prevIndex = index === 0 ? length - 1 : index - 1;
  
      setActiveIndex(prevIndex);
    };
  
    const goToNextSlide = (e) => {
      e.preventDefault();
  
      let index = activeIndex;
      let { length } = images;
      let nextIndex = (index === length - 1) ? 0 : index + 1;
  
      setActiveIndex(nextIndex);
    };
  
    return (
      <div className='carousel slide' data-ride='carousel'>
        <div className='carousel-inner'>
          {images.map((imageName, index) => {
            return (
              <div key={index} className={`carousel-item text-center ${index === activeIndex ? 'active' : ''}`}>
                <ImageComponent filename={imageName} alt={imageName} className={'d-block w-100 text-center'} />
                <div className='carousel-caption d-none d-md-block text-dark'>
                    <h5>{imageName}</h5>
                </div>
              </div>
            );
          })}
        </div>
        <a className='carousel-control-prev' href='#' role='button' onClick={e => goToPrevSlide(e)}>
          <span className='carousel-control-prev-icon text-primary text-primary bg-primary rounded' aria-hidden='true'></span>
          <span className='sr-only text-primary ms-2'>Previous</span>
        </a>
        <a className='carousel-control-next' href='#' role='button' onClick={e => goToNextSlide(e)}>
          <span className='sr-only text-primary me-2'>Next</span>
          <span className='carousel-control-next-icon text-primary bg-primary rounded' aria-hidden='true'></span>
        </a>

        <div className='carousel-indicators d-flex justify-content-center'>
          {images.map((image, index) => (
            <div key={index} 
              className={`bg-secondary me-3 ${index === activeIndex ? 'active' : ''}`} 
              onClick={() => goToSlide(index)}></div>
          ))}
        </div>
      </div>
    );
  };


const t = {
  id : 'ID',
  serialNum : 'SerialNum',
  imei : 'IMEI',
  kva : "kVA",
  state : "State",
  transformerInventoryNum : "MNS",
  datePaired : "Paired Time",
  user : "Paired By",
  email : "Paired By Email",
  dateInstalled : "Installed Time",
  userInstalled : "Installed By",
  userInstalledEmail : "Installed Email",
}