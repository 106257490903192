import React, { useState } from 'react'
import { t } from 'i18next'
import useDigitInput from 'react-digit-input';
import { useEffect } from 'react';


const DigitInputPage = ({ onSubmit, text, text2, size, dashes}) => {

  // component states 
  const [ readyToSubmit, setReadyToSubmit] = useState(false)
  const [inputActive, setInputActive] = useState(false)
  const [value, onChange] = useState('');


  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: size,
    value,
    onChange,
  });

  // if hitting backspace on first input, set to not active 


  // on value change 
  useEffect(() => {

    // if value is not empty, set to active 
    if(value.trim() !== ''){
        setInputActive(true)
    } 
    // else if input is active, turn back to not active
    else if(inputActive){
        setInputActive(false)
    }
    
    // check if all inputs are filled out 
    if(value.indexOf(' ') < 0 && value !== ''){
       setReadyToSubmit(true) 
    } else{
        setReadyToSubmit(false) 
    }
    
  }, [value, inputActive])

  return (
    <div className='full-page container tln-input-page'>
        <div>
            <p>{t(text)}</p>
        </div>

        <div className={`tln-input ${inputActive ? 'active' : ''}`}>
            <div className="input-group d-flex justify-content-between">
                {[...Array(size).keys()].map(num => {
                    return <React.Fragment key={num}>
                            <input inputMode="decimal" autoFocus={num === 0} {...digits[num]} placeholder="0" />
                            {dashes.includes(num) && num < size - 1 && <span className="hyphen">-</span>}
                        </React.Fragment>
                       
                })}
            </div>

        </div>
        <div className='text-center mb-4'>
            <button
              onClick={() => onSubmit(value)}
              className={`btn btn-primary standard-btn fw-bold ${!readyToSubmit && 'btn-disabled'}`}>{t('complete')}</button>
        </div>
        {text2 && <div className="my-4 text-center" >
            <button style={{color : '#3FB3FB', backgroundColor: 'transparent', border: 'none'}} className='text-decoration-none' onClick={() => onSubmit(null)} >{t('tln_unavailable')}</button>
        </div>}
       
    </div>
  )
}

export default DigitInputPage