// import dependencies
import React, { useState } from 'react'
import * as cn from '../redux/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { t } from 'i18next';

const ChooseInstallationType = () => {

    // Hooks 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Global State 
    const installationTypeSelected = useSelector(state => state.installationTypeSelected)

    // Component States 
    const [typeState, setTypeState] = useState(installationTypeSelected ? installationTypeSelected : null)

    // Find selected type in types arr and set the state to the selected 
    const handleSelectInstallType = value => {
        const foundType = INSTALL_TYPE_LIST.find(type => type.value === value)
        setTypeState(foundType)
    }

    // Save install type to global state and local storage, navigate to home
    const handleChooseInstallTypeBtn = () => {
        dispatch({
            type : cn.INSTALLATION_TYPE_SET,
            payload : typeState
        })
        localStorage.setItem('install_type', JSON.stringify(typeState))
        //navigate('/')
    }

  return (
    <div className='full-page container'>
        <div>
            <p>{t('install_type_text')}</p>
        </div>
        <div className='my-3 py-4'>

            {/* Render out all install types */}
            {INSTALL_TYPE_LIST && INSTALL_TYPE_LIST.length > 0 ? (
                <>
                    {INSTALL_TYPE_LIST.map((type,i) => (
                        <div key={type.value + i} className="my-3 ms-1 form-check d-flex flex-column gap-3" 
                             onChange={e => handleSelectInstallType(e.target.value)}>
                            <div className="ubi-radio-input">
                                <input className="form-check-input" value={type.value} type="radio" name="flexRadioDefault"/>
                                <label className="form-check-label">
                                {t(type.value)}
                                </label>
                            </div>
                        </div>
                    ))}
                </>
            ) : (<div className='text-center'><p>{t('no_installation_types_found')}</p></div>)}
        </div>
        
        <div className='text-center'>

            {/* Disable when no tyoe of installation is selected */}
            <button onClick={handleChooseInstallTypeBtn} 
                className={`mt-5 btn btn-primary standard-btn fw-bold ${typeState ? '' : 'btn-disabled'}`}>
                    {t('continue')}
            </button>
        </div>
    </div>
  )
}

export default ChooseInstallationType


const INSTALL_TYPE_LIST = [
    {
        id : 0, 
        value : 'field_install'
    },
    {
        id : 1,
        value : 'store_room'
    }, 
]