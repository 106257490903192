// import dependencies 
import React from 'react'
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// import assets
import arrowDown from '../img/arrow-down.svg'
import cloud from '../img/cloud.svg'
import { useSelector } from 'react-redux';


const HomeComponent = ({ installations, path }) => {

    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)

    return (
        <motion.div 
            className='full-page home-page'
            initial={{top: path === '/' ? '-1000px' : '1000px'}}
            animate={{top: '80px'}}
            exit={{top: '-1000px'}}
            transition={{duration: 0.4}}
            >
            <div className='container py-4 d-flex flex-column justify-content-around h-100'>

                {/* Add unit button */}
                <div className="text-center mt-5">
                    <Link to={"/install/scan-unit"} className="btn btn-primary standard-btn fw-bold ">{t('add_unit')}</Link>
                </div>

                {/* Render Unsuccessful Installs only */}
                <div className="home-install-summary">
                    {installationTypeSelected === 'field_install' && 
                        installations && installations.filter(install => !install.installationStatus?.successful).length > 0 && <>
                            <p className="fs-2 fw-bold text-nowrap">{t('unsuccessful_installs_table')}</p>
                            {installations.filter(install => !install.installationStatus?.successful).map((install, key) => (
                                <div className="row border-bottom py-2" key={key}>
                                    <div className="col">
                                        <p className="mb-0 mt-2">{install.serialNum}</p>
                                    </div>

                                    {/* Check if Install was submitted to DB or is local is saved locally on the device */}
                                    {!install.submittedToDB ? (
                                        <div className='text-center col-2'>
                                            <img src={cloud} className="img-fluid mt-2" alt="" />
                                        </div>
                                    ) : <div className='col-2'></div>}
                                    <div className="col-3">
                                        <div className='status-circle-red ms-auto mt-2'></div>
                                    </div>
                                </div>
                            ))}
                       </>}
                </div>
            </div>
        
            {/* Page Navigator Link  */}
            <div className={`click-to-slide-up display-at-bottom`} >
                <Link to={'/logs/view'} className='bottom-bar-title d-flex justify-content-center 
                text-light align-items-center text-decoration-none'>
                    <span className='ps-4' >{t('view_log')}</span>
                    <img className="ubi-arrow" src={arrowDown} alt="ubi arrow down" />
                </Link>
            </div>
        </motion.div>
    )
}

export default HomeComponent